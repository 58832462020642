import {makeAutoObservable} from 'mobx'
import {InstitutionsStore} from 'src/store/InstitutionsStore'
import {LoginStore} from 'src/store/LoginStore'
import UsersStore from 'src/store/UserStore'
import {AzureStorageStore} from 'src/store/AzureStorageStore'
import {StudentsStore} from 'src/store/StudentsStore'
import {PaymentsStore} from 'src/store//PaymentStore'
import {CodeTablesStore} from 'src/store/CodeTablesStore'

export type MessageType = 'success' | 'info' | 'warning' | 'error'

export class MainStore {
  readonly institutionStore: InstitutionsStore

  readonly loginStore: LoginStore

  readonly usersStore: UsersStore

  readonly azureStorageStore: AzureStorageStore

  readonly studentsStore: StudentsStore

  readonly paymentsStore: PaymentsStore

  readonly codeTablesStore: CodeTablesStore

  message: string = ''

  messageType: MessageType = 'error'

  constructor() {
    this.loginStore = new LoginStore(this)
    this.institutionStore = new InstitutionsStore(this)
    this.usersStore = new UsersStore(this)
    this.azureStorageStore = new AzureStorageStore(this)
    this.studentsStore = new StudentsStore(this)
    this.paymentsStore = new PaymentsStore(this)
    this.codeTablesStore = new CodeTablesStore(this)
    makeAutoObservable(this)

    this.loginStore.init()
  }

  setMessage = (message: string, messageType: MessageType = 'error') => {
    this.message = message
    this.messageType = messageType
  }
}
