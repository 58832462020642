import {Alert, Box, IconButton, Snackbar, Stack} from '@mui/material'
import {Outlet} from 'react-router-dom'
import NavMini from 'src/components/layout/NavMini'
import Header from './Header'
import {useMainStore} from 'src/contexts/Main'
import {CloseIcon} from 'src/theme/overrides/CustomIcons'
import {observer} from 'mobx-react-lite'
import {NAV} from 'src/config'

const Main = observer(() => {
  const {message, messageType, setMessage} = useMainStore()
  const resetError = () => {
    setMessage('')
  }

  return (
    <Stack height="100%" sx={{direction: 'rtl'}}>
      {message && (
        <Snackbar
          anchorOrigin={{vertical: 'top', horizontal: 'center'}}
          open
          autoHideDuration={6000}
          onClose={resetError}
          sx={{zIndex: theme => theme.zIndex.snackbar}}
        >
          <Alert
            severity={messageType}
            action={
              <IconButton
                sx={{pr: 2}}
                aria-label="close"
                color="inherit"
                size="small"
                onClick={resetError}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {message}
          </Alert>
        </Snackbar>
      )}
      <Header />
      <Box display="flex" flexDirection="column" height="100%" overflow="auto">
        <Box flexGrow={1} sx={{display: 'inline-flex'}}>
          <NavMini />
          <Stack
            sx={{
              backgroundColor: theme => theme.palette.background.neutral
            }}
            maxWidth={{lg: `calc(100% - ${NAV.W_DASHBOARD_MINI}px)`}}
            component="main"
            flexGrow={1}
            direction="row"
            px={5}
            py={3}
          >
            <Outlet />
          </Stack>
        </Box>
      </Box>
    </Stack>
  )
})

export default Main
