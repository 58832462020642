import {observer} from 'mobx-react-lite'
import {useMainStore} from 'src/contexts/Main'
import LabelCol from '../grid/columnTypes/LabelCol'
import {
  GridColDef,
  GridFilterModel,
  GridLogicOperator,
  GridRowHeightParams
} from '@mui/x-data-grid-pro'
import {format} from 'date-fns'
import {LabelColor} from '../label'
import {Box, Paper, Stack, Typography} from '@mui/material'
import DataGridWrapper from '../grid/DataGridWrapperNew'
import {useState} from 'react'
import InstitutionalPaymentsToolBar from './InstitutionalPaymentsToolBar'
import {singleSelectFilterFunc} from 'src/components/ui/SingleSelectFilter'
import InstitutionNameCol from 'src/components/grid/columnTypes/InstitutionNameCol'
import InstitutionalPaymentsSummery from './InstitutionalPaymentsSummery'
import Payment from 'src/entities/payment'
import {monthsWithId} from 'src/utils/month'

const InstitutionalPayments = observer(() => {
  const mainStore = useMainStore()

  const {currentUser} = mainStore.loginStore
  const {paymentsStatuses} = mainStore.paymentsStore

  const {institutionsStatuses, paymetByMonth} = mainStore.institutionStore

  const notPaid = paymentsStatuses.find(p => p.id === 5)

  const [filterModel, setFilterModel] = useState({
    items: [],
    linkOperator: GridLogicOperator.And
  } as GridFilterModel)

  const [filterButtonEl, setFilterButtonEl] =
    useState<HTMLButtonElement | null>(null)

  const columns: GridColDef[] = [
    {
      field: 'paymentReportId',
      headerName: 'מזהה דו”ח',
      headerAlign: 'center',
      width: 60,
      type: 'number',
      align: 'center',
      flex: 1,
      valueGetter: (_, row) => {
        if (row?.payments?.length > 0) {
          return row?.payments[0].paymentReport?.id
        }
        return null
      }
    },
    {
      field: 'id',
      headerName: 'מזהה מוסד',
      headerAlign: 'center',
      width: 60,
      sortable: true,
      filterable: true,
      type: 'number',
      align: 'center',
      flex: 1
    },
    {
      field: 'name',
      headerName: 'שם מוסד',
      headerAlign: 'center',
      minWidth: 240,
      sortable: true,
      filterable: true,
      type: 'string',
      align: 'center',
      flex: 1,
      renderCell: params => (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%'
          }}
        >
          <InstitutionNameCol name={params.row.name} id={params.row.id} />
        </Box>
      )
    },
    {
      field: 'institutionHeadName',
      headerName: 'ראש מוסד',
      headerAlign: 'center',
      minWidth: 240,
      sortable: true,
      filterable: true,
      type: 'string',
      align: 'center',
      flex: 1
    },
    {
      field: 'statusId',
      headerName: 'סטטוס מוסד',
      maxWidth: 110,
      sortable: true,
      filterable: true,
      align: 'center',
      flex: 1,
      headerAlign: 'center',
      valueFormatter: (_, row) => {
        const status = institutionsStatuses?.find(v => v.id === row.statusId)

        return status?.description
      },
      valueGetter: (_, row) => row?.statusId || '',
      renderCell: (params: any) => {
        const status = institutionsStatuses?.find(
          v => v.id === params.row.statusId
        )
        return (
          <LabelCol
            text={status?.description || ''}
            color={(status?.color || 'primary') as LabelColor}
          />
        )
      },
      type: 'singleSelect',
      filterOperators: singleSelectFilterFunc(institutionsStatuses)
    },
    {
      field: 'monthStatus',
      headerName: 'חודש סטטוס',
      headerAlign: 'center',
      width: 180,
      type: 'string',
      align: 'center',
      editable: false,
      flex: 1,
      valueFormatter: (params: any) => {
        return params
          ? monthsWithId?.find((s: any) => s.id === params)?.name
          : ''
      },
      renderCell: (params: any) => {
        return params
          ? monthsWithId?.find((s: any) => s.id === params)?.name
          : ''
      }
    },
    {
      field: 'payDate',
      headerName: 'תאריך תשלום',
      minWidth: 150,
      type: 'date',
      align: 'center',
      flex: 1,
      headerAlign: 'center',
      valueGetter: (_, row) => {
        if (row?.payments?.length > 0) {
          const payDate = row?.payments[0].paymentReport?.payDate
          return payDate ? new Date(payDate) : null // Return a Date object or null
        }
        return null
      },
      valueFormatter: (_, row) => {
        const payDate =
          row?.payments?.length > 0
            ? row.payments[0].paymentReport?.payDate
            : undefined
        if (!payDate) return ''
        const date = new Date(payDate)
        return format(date, 'dd.MM.yy')
      }
    },
    {
      field: 'amount',
      headerName: 'סכום',
      headerAlign: 'center',
      type: 'number',
      width: 120,
      align: 'center',
      flex: 1,
      valueFormatter: (_, row) => {
        const payments = row?.payments || []
        if (payments.length === 0) return '0 ש"ח'

        // Format each payment's amount and join them with commas
        return payments
          .map((payment: Payment) => {
            return `${new Intl.NumberFormat('en-US').format(
              payment?.amount || 0
            )} ש"ח`
          })
          .join(', ')
      },
      renderCell: params => {
        const payments = params.row?.payments || []
        if (payments.length === 0) return ''

        return (
          <Box
            sx={{
              display: 'flex',
              height: '100%',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {payments.map((payment: Payment, index: number) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  height: '64px',
                  mt: '7px'
                }}
              >
                <Typography
                  sx={{fontWeight: 'bold'}}
                  variant="body2"
                  component="div"
                >
                  {payment?.amount
                    ? new Intl.NumberFormat('en-US').format(payment?.amount)
                    : '0'}{' '}
                  ש"ח
                </Typography>
              </Box>
            ))}
          </Box>
        )
      }
    },

    {
      field: 'payments',
      headerName: 'סטטוס תשלום',
      maxWidth: 110,
      sortable: true,

      filterable: true,
      align: 'center',
      flex: 1,
      headerAlign: 'center',
      valueFormatter: (_, row) => {
        const payments = row?.payments || []
        if (payments.length === 0) {
          return notPaid?.description || ''
        }
        return payments
          .map((payment: Payment) => {
            const status = paymentsStatuses?.find(
              v => v.id === payment.statusId
            )
            return status?.description || ''
          })
          .join(', ')
      },
      valueGetter: (_, row) => {
        const payments =
          row?.payments?.length > 0 ? row?.payments : [{statusId: 5}] // Default when no payments
        return payments
      },
      renderCell: (params: any) => {
        const payments = params.row?.payments || []
        if (payments.length === 0)
          return (
            <LabelCol
              text={notPaid?.description || ''}
              color={(notPaid?.color || 'primary') as LabelColor}
            />
          )

        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            {payments.map((payment: Payment, index: number) => {
              const status = paymentsStatuses?.find(
                v => v.id === payment.statusId
              )
              return (
                <LabelCol
                  key={index}
                  text={status?.description || ''}
                  color={(status?.color || 'primary') as LabelColor}
                />
              )
            })}
          </Box>
        )
      },

      type: 'singleSelect',
      filterOperators: singleSelectFilterFunc(paymentsStatuses, 'statusId')
    }
  ]

  return (
    <>
      <Stack width={'100%'} height={'100%'} spacing={2}>
        <Typography variant="subtitle2">
          שלום {currentUser?.fullName}
        </Typography>
        <Paper sx={{width: '100%', p: 3}}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant={'h5'}> תשלומים למוסדות </Typography>

            <InstitutionalPaymentsSummery />
          </Stack>
        </Paper>

        <Paper
          sx={{
            width: '100%',
            height: '100%',
            p: 2,
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,

            maxHeight: {xs: 'calc(100vh - 100px)', md: 'calc(100vh - 150px)'}
          }}
        >
          <DataGridWrapper
            columns={columns}
            rows={paymetByMonth}
            hideFooter
            getRowHeight={(params: GridRowHeightParams) => {
              const row = paymetByMonth?.find(r => r.id === params.id) // Get row by id
              if (!row || row?.payments?.length === 0) {
                return 64
              }
              const payments = row?.payments || []
              return payments.length * 40
            }}
            isCellEditable={(params: any) => {
              return params.row.statusId !== 2
            }}
            filterModel={filterModel}
            disableColumnMenu
            onFilterModelChange={(newValue: GridFilterModel) => {
              setFilterModel(newValue)
            }}
            slots={{
              toolbar: InstitutionalPaymentsToolBar
            }}
            slotProps={{
              panel: {
                anchorEl: filterButtonEl
              },
              baseSelect: {native: false, defaultValue: ''},
              toolbar: {
                setFilterButtonEl: setFilterButtonEl,
                setFilterModel: setFilterModel
              },
              filterPanel: {
                filterFormProps: {
                  logicOperatorInputProps: {
                    variant: 'outlined',
                    size: 'small'
                  },
                  columnInputProps: {
                    variant: 'outlined',
                    size: 'small',
                    sx: {mt: 'auto'}
                  },
                  operatorInputProps: {
                    variant: 'outlined',
                    size: 'small',
                    sx: {mt: 'auto'}
                  },
                  valueInputProps: {
                    InputComponentProps: {
                      variant: 'outlined',
                      size: 'small'
                    }
                  }
                },
                sx: {
                  '& .MuiDataGrid-panelFooter': {
                    justifyContent: 'flex-start',
                    px: 2.5
                  },
                  '& .MuiDataGrid-panelFooter .MuiButton-root:last-of-type': {
                    color: (theme: any) => theme.palette.primary.main,
                    backgroundColor: 'transparent',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 171, 85, 0.08)'
                    }
                  },
                  '& .MuiDataGrid-filterForm': {p: 2},
                  '& .MuiDataGrid-filterFormLogicOperatorInput': {
                    mr: 1,
                    width: 75
                  },
                  '& .MuiDataGrid-filterFormColumnInput': {mr: 1, width: 150},
                  '& .MuiDataGrid-filterFormOperatorInput': {
                    mr: 1,
                    width: 150
                  },
                  '& .MuiDataGrid-filterFormValueInput': {width: 280},
                  '& .MuiDataGrid-filterFormDeleteIcon': {
                    justifyContent: 'center'
                  }
                }
              }
            }}
          />
        </Paper>
      </Stack>
    </>
  )
})

export default InstitutionalPayments
