import React, {useState} from 'react'
import {Menu, MenuItem, Typography} from '@mui/material'
import {useMainStore} from '../../contexts/Main'

interface SelectableItemButtonProps<T> {
  currentItemId?: number | string
  defaultText?: string
  items: T[]
  onChange: (institutionId: number, itemId: string) => void
  isEditable: boolean
  getId: (item: T) => number | string
  getDisplayName: (item: T) => string
}

const SelectableItemButton = <T,>({
  currentItemId,
  items = [],
  defaultText = 'Select an option',
  onChange,
  isEditable,
  getId = (item: any) => item.id, // Default getId function
  getDisplayName = (item: any) => item.fullName // Default getDisplayName function
}: SelectableItemButtonProps<T>): React.ReactElement => {
  const {currentInstitution} = useMainStore().institutionStore
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (isEditable) setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleItemChange = (itemId: number | string) => {
    handleClose()
    onChange(currentInstitution?.id ?? 0, String(itemId))
  }

  const currentItem =
    items && currentItemId !== undefined
      ? items.find(item => getId(item) === currentItemId)
      : undefined

  return (
    <>
      <Typography
        variant="h5"
        style={{cursor: 'pointer', marginBottom: '8px'}}
        onClick={handleClick}
      >
        {currentItem ? getDisplayName(currentItem) : defaultText}
      </Typography>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
        transformOrigin={{vertical: 'top', horizontal: 'left'}}
      >
        {items.map(item => (
          <MenuItem
            key={getId(item)}
            onClick={() => handleItemChange(getId(item))}
          >
            {getDisplayName(item)}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default SelectableItemButton
